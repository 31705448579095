






















import { PropType } from 'vue';
import StatsCard from '@/components/StatsCard.vue';
import {
  EWheelOfFortuneCampaignEventType,
  IWheelOfFortuneEvent
} from '@/api/schema';

export default {
  name: 'CopyStakeWheelOfFortuneCampaignDialogTotalRow',
  components: {
    StatsCard
  },
  props: {
    campaignEvents: {
      type: Array as PropType<IWheelOfFortuneEvent[]>,
      default: (): IWheelOfFortuneEvent[] => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    monetaryPrizesValue(): number {
      return this.campaignEvents
        .filter(
          (event: IWheelOfFortuneEvent) =>
            event.type === EWheelOfFortuneCampaignEventType.MONETARY
        )
        .reduce(
          (sum: number, event: IWheelOfFortuneEvent) =>
            sum + Number(event.amountUsd || 0),
          0
        );
    },
    promoCodesValue(): number {
      return this.campaignEvents
        .filter(
          (event: IWheelOfFortuneEvent) =>
            event.type === EWheelOfFortuneCampaignEventType.PROMO_CODE
        )
        .reduce(
          (sum: number, event: IWheelOfFortuneEvent) =>
            sum + Number(event.amountUsd || 0),
          0
        );
    },
    totalBonusesValue(): number {
      return this.monetaryPrizesValue + this.promoCodesValue;
    }
  }
};
