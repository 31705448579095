








































import { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import { IWheelOfFortuneStreamer, TStreamer } from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const defaultOptions = {
  page: 1,
  itemsPerPage: 5
};

export default {
  name: 'CopyStakeWheelOfFortuneCampaignDialogStreamerTab',
  components: {
    CopyAddress: (): any => import('@/components/CopyAddress.vue'),
    CopyStakeSelectStreamersDialog: (): any =>
      import(
        '@/views/CopyStake/CopyStakeStreamer/CopyStakeSelectStreamersModal.vue'
      )
  },
  props: {
    campaignStreamer: {
      type: Object as PropType<IWheelOfFortuneStreamer | null>,
      default: null
    },
    isValid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      dialogs: {
        selectStreamersDialog: false,
        isLoading: false,
        data: null
      },
      headers: [
        {
          text: 'Operator User ID',
          value: 'streamerOperatorUserId',
          width: '25%'
        },
        { text: 'Wallet Address', value: 'streamerWallet', width: '25%' },
        { text: 'Nickname', value: 'streamerNickName', width: '25%' },
        { text: 'Action', value: 'actions', width: '25%' }
      ] as DataTableHeader[],
      tableOptions: { ...defaultOptions }
    };
  },
  computed: {
    streamer: {
      get(): IWheelOfFortuneStreamer {
        return this.campaignStreamer;
      },
      set(streamer: IWheelOfFortuneStreamer): void {
        this.$emit('update:isValid', !!streamer);
        this.$emit('updateStreamer', streamer);
      }
    },
    tableItems(): IWheelOfFortuneStreamer[] {
      return this.campaignStreamer ? [this.campaignStreamer] : [];
    }
  },
  methods: {
    handlerClickSelectStreamerBtn(): void {
      this.openCopyStakeSelectStreamersDialog();
    },
    openCopyStakeSelectStreamersDialog(): void {
      this.dialogs.selectStreamersDialog = true;
    },
    closeCopyStakeSelectStreamersDialog(): void {
      this.dialogs.selectStreamersDialog = false;
    },
    handlerSelectCopyStakeSelectStreamersDialog(
      streamers: TStreamer[]
    ): void {
      try {
        const newStreamer = this.formatStreamers(streamers)[0];

        this.streamer = newStreamer ? { ...newStreamer } : null;

        this.closeCopyStakeSelectStreamersDialog();
      } catch (error) {
        errorToastMessage(error);
      }
    },
    handlerClickDeleteStreamerButton(): void {
      this.streamer = null;
    },
    formatStreamers(streamers: TStreamer[]): IWheelOfFortuneStreamer[] {
      return streamers.map((streamer: TStreamer) => ({
        streamerId: streamer.id,
        streamerUserId: streamer.playerId,
        streamerNickName: streamer.nickname,
        streamerOperatorUserId: streamer.operatorUserId,
        streamerWallet: streamer.playerWalletHash
      }));
    }
  }
};
