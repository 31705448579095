














































































import { PropType } from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import CopyStakeWheelOfFortuneCampaignDialogMainForm from '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/ManageCampaignDialog/CopyStakeWheelOfFortuneCampaignDialogMainForm.vue';
import CopyStakeWheelOfFortuneCampaignDialogEventsTab from '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/ManageCampaignDialog/CopyStakeWheelOfFortuneCampaignDialogEventsTab.vue';
import CopyStakeWheelOfFortuneCampaignDialogStreamerTab from '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/ManageCampaignDialog/CopyStakeWheelOfFortuneCampaignDialogStreamerTab.vue';
import CopyStakeWheelOfFortuneCampaignDialogTotalRow from '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/ManageCampaignDialog/CopyStakeWheelOfFortuneCampaignDialogTotalRow.vue';
import {
  createCampaign,
  getCampaignDetails,
  updateCampaign
} from '@/api/CopyStake/GamificationCenter/WheelOfFortune';
import {
  EWheelOfFortuneCampaignEventType,
  EWheelOfFortuneCampaignStatusType,
  IWheelOfFortuneCampaignDetails,
  IWheelOfFortuneEvent,
  IWheelOfFortuneStreamer,
  TTabsItem
} from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { AxiosError } from 'axios';

const ETabKeys = {
  EVENTS: 'EVENTS',
  STREAMER: 'STREAMER'
};

const defaultCampaign = {
  name: null,
  startAt: null,
  finishAt: null,
  activationType: null,
  minViewersThreshold: null,
  streamer: null,
  events: []
};

const defaultServerErrors = {
  name: null
};

export default {
  name: 'CopyStakeWheelOfFortuneCampaignDialog',
  components: {
    BaseDialog,
    CopyStakeWheelOfFortuneCampaignDialogMainForm,
    CopyStakeWheelOfFortuneCampaignDialogEventsTab,
    CopyStakeWheelOfFortuneCampaignDialogStreamerTab,
    CopyStakeWheelOfFortuneCampaignDialogTotalRow
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    campaignId: {
      type: Number as PropType<number> | null,
      default: null
    },
    isCopyMode: {
      type: Boolean,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      ETabKeys,
      EWheelOfFortuneCampaignEventType,

      tabsKeyValue: ETabKeys.EVENTS,

      formData: { ...defaultCampaign },
      editableCampaign: null,

      serverErrors: { ...defaultServerErrors },
      isLoading: false,
      isFormsValid: {
        mainForm: false,
        eventsForm: false,
        streamerForm: false
      }
    };
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    dialogTitle(): string {
      if (this.isViewMode) {
        return 'Campaign Details';
      } else if (this.isEditMode) {
        return 'Edit Campaign';
      }

      return 'Add Campaign';
    },
    isEditMode(): boolean {
      return !!this.campaignId && !this.isCopyMode;
    },
    isViewMode(): boolean {
      return (
        this.isEditMode &&
        this.editableCampaign?.status !==
          EWheelOfFortuneCampaignStatusType.PENDING
      );
    },
    tabsParams(): TTabsItem[] {
      return [
        {
          text: 'Prizes',
          id: ETabKeys.EVENTS
        },
        {
          text: 'Streamer',
          id: ETabKeys.STREAMER
        }
      ].filter((tab: TTabsItem) => !tab.visible);
    },
    isAllFormsValid(): boolean {
      return !!(
        this.isFormsValid.mainForm &&
        this.isFormsValid.streamerForm &&
        this.isFormsValid.eventsForm
      );
    },
    isShowSubmitBtn(): boolean {
      return !this.isViewMode;
    },
    isDisabledSubmitBtn(): boolean {
      return !this.isAllFormsValid || this.isLoading;
    }
  },
  watch: {
    async campaignId(id: number): Promise<void> {
      if (id) {
        await this.setCampaignDetails();
      }
    }
  },
  methods: {
    async setCampaignDetails(): Promise<void> {
      if (!this.campaignId) return;

      try {
        this.isLoading = true;

        const campaignDetailsData = await getCampaignDetails(this.campaignId);

        this.editableCampaign = { ...campaignDetailsData };

        this.formData = {
          name: this.isCopyMode
            ? campaignDetailsData.name + ' (Copied)'
            : campaignDetailsData.name,
          startAt: campaignDetailsData.startAt,
          finishAt: campaignDetailsData.finishAt,
          activationType: campaignDetailsData.activationType,
          minViewersThreshold: campaignDetailsData.minViewersThreshold,
          streamer: { ...campaignDetailsData.streamer },
          events: [...campaignDetailsData.events]
        };

        if (campaignDetailsData.streamer) {
          this.isFormsValid.streamerForm = true;
        }
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    updateCampaignDetailsHandler(
      campaign: IWheelOfFortuneCampaignDetails
    ): void {
      this.formData = { ...this.formData, ...campaign };
    },
    updateEventsHandler(events: IWheelOfFortuneEvent[]): void {
      this.formData.events = [...events];
    },
    updateStreamerHandler(streamer: IWheelOfFortuneStreamer): void {
      this.formData.streamer = streamer ? { ...streamer } : null;
    },
    async handlerClickSubmitButton(): Promise<void> {
      try {
        if (!this.isAllFormsValid) return;

        this.isLoading = true;

        this.isEditMode
          ? await updateCampaign(this.campaignId, this.formData)
          : await createCampaign(this.formData);

        this.$toast.success(
          `Campaign has been successfully ${
            this.isEditMode ? 'updated' : 'created'
          }.`
        );

        this.isLoading = false;
        this.closeDialog();
        this.$emit('submit');
      } catch (error) {
        this.parseServerErrors(error);
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handlerClickCloseBtn(): Promise<void> {
      this.closeDialog();
    },
    parseServerErrors(error: AxiosError): void {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message;

        if (!errorMessage) return;

        if (errorMessage.includes('] already exists')) {
          this.serverErrors.name = errorMessage;
        }
      }
    },
    resetForms(): void {
      this.resetServerErrors();

      this.tabsKeyValue = ETabKeys.EVENTS;
      this.formData = {
        ...defaultCampaign
      };
    },
    resetServerErrors(key?: string): void {
      if (key && this.serverErrors[key]) {
        this.serverErrors[key] = null;
      } else {
        this.serverErrors = { ...defaultServerErrors };
      }
    },
    closeDialog(): void {
      if (this.isLoading) return;

      this.resetForms();

      this.$emit('close');
      this.showDialog = false;
    }
  }
};
