






















































































import { PropType } from 'vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import {
  EWheelOfFortuneCampaignActivationType,
  EWheelOfFortuneCampaignEventType,
  IWheelOfFortuneCampaignDetails,
  ValidationRules
} from '@/api/schema';
import { DateTime } from 'luxon';
import * as vr from '@/helpers/validation';

export default {
  name: 'CopyStakeWheelOfFortuneCampaignDialogMainForm',
  components: {
    VExtendedDataPicker
  },
  props: {
    campaignData: {
      type: Object as PropType<IWheelOfFortuneCampaignDetails>,
      default: (): IWheelOfFortuneCampaignDetails =>
        ({} as IWheelOfFortuneCampaignDetails)
    },
    isValid: {
      type: Boolean,
      default: false
    },
    serverErrors: {
      type: Object,
      default: (): any => {
        return {
          name: null
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      activateTypes: [
        {
          value: EWheelOfFortuneCampaignActivationType.BY_OPERATOR,
          label: 'Activate by Operator'
        },
        {
          value: EWheelOfFortuneCampaignActivationType.BY_STREAMER,
          label: 'Activate by Streamer'
        }
      ],
      eventTypes: [
        {
          value: EWheelOfFortuneCampaignEventType.MONETARY,
          label: 'Monetary Prize'
        },
        {
          value: EWheelOfFortuneCampaignEventType.PROMO_CODE,
          label: 'Promo Code'
        }
      ]
    };
  },
  computed: {
    isFormValid: {
      get(): boolean {
        return this.isValid;
      },
      set(value: boolean): void {
        this.$emit('update:isValid', value);
      }
    },
    campaignDetails: {
      get(): IWheelOfFortuneCampaignDetails {
        return this.campaignData;
      },
      set(campaignDetails: IWheelOfFortuneCampaignDetails): void {
        this.$emit('updateCampaign', campaignDetails);
      }
    },
    rules(): ValidationRules {
      return {
        name: [(v) => vr.required(v, 'Campaign name')],
        startAt: [
          vr.required,
          (v) => {
            const nowUtc = DateTime.utc().toFormat('yyyy-MM-dd HH:mm');

            const isValid =
              vr.isDateTimeAfterOrEqual(v, nowUtc) === true &&
              vr.isDateNotEqual(v, nowUtc) === true;

            return isValid === true
              ? true
              : 'Start date must be in the future (UTC)';
          }
        ],
        finishAt: [
          vr.required,
          (v) => vr.isDateNotEqual(v, this.campaignDetails.startAt),
          (v) => vr.isDateTimeAfterOrEqual(v, this.campaignDetails.startAt)
        ],
        activationType: [(v) => vr.required(v, 'Activate type')],
        minViewersThreshold: [
          (v) => (v ? vr.validNumber(v) : true),
          (v) => (v ? vr.positiveNumber(v) : true)
        ]
      };
    }
  },
  watch: {
    'campaignDetails.startAt'(): void {
      this.$nextTick(() => {
        if (this.campaignDetails.finishAt)
          this.$refs.finishAtCampaign.validate();
      });
    }
  },
  methods: {
    handlerCampaignNameInput(): void {
      if (this.serverErrors.name) this.$emit('resetServerErrors', 'name');
    }
  }
};
