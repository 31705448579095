import { render, staticRenderFns } from "./CopyStakeWheelOfFortuneCampaignDialogMainForm.vue?vue&type=template&id=76b9535e&scoped=true&lang=pug"
import script from "./CopyStakeWheelOfFortuneCampaignDialogMainForm.vue?vue&type=script&lang=ts"
export * from "./CopyStakeWheelOfFortuneCampaignDialogMainForm.vue?vue&type=script&lang=ts"
import style0 from "./CopyStakeWheelOfFortuneCampaignDialogMainForm.vue?vue&type=style&index=0&id=76b9535e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b9535e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VForm,VRow,VSelect,VTextField})
