var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form"},[_c('v-form',{ref:"form",attrs:{"disabled":_vm.loading || _vm.disabled},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form__input",attrs:{"label":"Campaign Name","placeholder":"Please type the campaign name...","error-messages":_vm.serverErrors.name,"rules":_vm.rules.name,"outlined":"","dense":""},on:{"input":_vm.handlerCampaignNameInput},model:{value:(_vm.campaignDetails.name),callback:function ($$v) {_vm.$set(_vm.campaignDetails, "name", $$v)},expression:"campaignDetails.name"}}),_c('v-extended-data-picker',{attrs:{"menu-props":{ left: false, nudgeBottom: -20 },"min-today-utc":"","time-select":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var open = ref.open;
return [_c('div',_vm._g({staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form__input",class:{ 'disabled': _vm.disabled }},on),[_c('v-text-field',_vm._b({ref:"startAtCampaign",attrs:{"label":"Campaign starts at (UTC)","value":_vm.campaignDetails.startAt,"prepend-inner-icon":"mdi-calendar-blank","rules":open ? [] : _vm.rules.startAt,"readonly":"","dense":"","outlined":""}},'v-text-field',attrs,false))],1)]}}]),model:{value:(_vm.campaignDetails.startAt),callback:function ($$v) {_vm.$set(_vm.campaignDetails, "startAt", $$v)},expression:"campaignDetails.startAt"}}),_c('v-extended-data-picker',{attrs:{"min":_vm.campaignDetails.startAt,"menu-props":{ left: false, nudgeBottom: -20 },"min-today-utc":"","time-select":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var open = ref.open;
return [_c('div',_vm._g({staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form__input",class:{ 'disabled': _vm.disabled || !_vm.campaignDetails.startAt }},on),[_c('v-text-field',_vm._b({ref:"finishAtCampaign",attrs:{"label":"Campaign ends at (UTC)","value":_vm.campaignDetails.finishAt,"disabled":!_vm.campaignDetails.startAt || _vm.disabled,"prepend-inner-icon":"mdi-calendar-blank","rules":open ? [] : _vm.rules.finishAt,"readonly":"","dense":"","outlined":""}},'v-text-field',_vm.campaignDetails.startAt ? attrs : null,false))],1)]}}]),model:{value:(_vm.campaignDetails.finishAt),callback:function ($$v) {_vm.$set(_vm.campaignDetails, "finishAt", $$v)},expression:"campaignDetails.finishAt"}}),_c('v-select',{staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form__input",attrs:{"items":_vm.activateTypes,"item-text":"label","label":"Activate type","rules":_vm.rules.activationType,"menu-props":{ offsetY: true, contentClass: 'list-style' },"outlined":"","dense":""},model:{value:(_vm.campaignDetails.activationType),callback:function ($$v) {_vm.$set(_vm.campaignDetails, "activationType", $$v)},expression:"campaignDetails.activationType"}}),_c('v-text-field',{staticClass:"copy-stake-wheel-of-fortune-campaign-dialog-main-form__input",attrs:{"label":"Minimal amount of viewers for activation (optional)","placeholder":"Please type the amount of viewers...","rules":_vm.rules.minViewersThreshold,"outlined":"","dense":""},model:{value:(_vm.campaignDetails.minViewersThreshold),callback:function ($$v) {_vm.$set(_vm.campaignDetails, "minViewersThreshold", $$v)},expression:"campaignDetails.minViewersThreshold"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }