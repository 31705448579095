









































































































































import { PropType } from 'vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import {
  EWheelOfFortuneCampaignActivationType,
  EWheelOfFortuneCampaignEventType,
  IWheelOfFortuneCampaignDetails,
  IWheelOfFortuneEvent,
  ValidationRules
} from '@/api/schema';
import * as vr from '@/helpers/validation';
import { isDateNotEqual, isDateTimeAfterOrEqual } from '@/helpers/validation';

export default {
  name: 'CopyStakeWheelOfFortuneCampaignDialogPrizesTab',
  components: {
    VExtendedDataPicker,
    Tooltip
  },
  props: {
    campaignData: {
      type: Object as PropType<IWheelOfFortuneCampaignDetails>,
      default: (): IWheelOfFortuneCampaignDetails =>
        ({} as IWheelOfFortuneCampaignDetails)
    },
    isValid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      vr,
      EWheelOfFortuneCampaignEventType,
      EWheelOfFortuneCampaignActivationType,
      eventTypes: [
        {
          value: EWheelOfFortuneCampaignEventType.MONETARY,
          label: 'Monetary Prize'
        },
        {
          value: EWheelOfFortuneCampaignEventType.PROMO_CODE,
          label: 'Promo Code'
        }
      ]
    };
  },
  computed: {
    isFormValid: {
      get(): boolean {
        return this.isValid;
      },
      set(value: boolean): void {
        this.$emit('update:isValid', !!(value && this.events.length));
      }
    },
    events: {
      get(): IWheelOfFortuneEvent[] {
        return this.campaignData.events;
      },
      set(events: IWheelOfFortuneEvent[]): void {
        this.$emit('updateEvents', events);
      }
    },
    rules(): ValidationRules {
      return {
        type: [vr.required],
        promoCode: [vr.required],
        amountUsd: [vr.required, vr.validNumber, vr.positiveNumber],
        startAt: [
          vr.required,
          (v) =>
            vr.isDateTimeAfterOrEqual(
              v,
              this.campaignData.startAt,
              'Can not be before campaign start date'
            ),
          (v) =>
            vr.isDateTimeAfterOrEqual(
              this.campaignData.finishAt,
              v,
              'Can not be after campaign end date'
            ),
          (v) =>
            vr.isDateNotEqual(
              this.campaignData.finishAt,
              v,
              'Can not be equal campaign end date'
            )
        ],
        finishAt: [
          vr.required,
          (v) =>
            vr.isDateTimeAfterOrEqual(
              this.campaignData.finishAt,
              v,
              'Can not be after campaign end date'
            )
        ]
      };
    },
    isDisabledAddPrizeBtn(): boolean {
      return !(
        this.campaignData.activationType &&
        this.campaignData.startAt &&
        this.campaignData.finishAt
      );
    }
  },
  watch: {
    'campaignData.startAt': 'hardDateValidate',
    'campaignData.finishAt': 'hardDateValidate'
  },
  methods: {
    isDateNotEqual,
    isDateTimeAfterOrEqual,
    handlerClickAddPrizeBtn(): void {
      this.addNewEvent();
    },
    handlerClickCopyPrizeBtn(index: number): void {
      this.addNewEvent(index);
    },
    handlerClickDeletePrizeBtn(index: number): void {
      this.events.splice(index, 1);
    },
    addNewEvent(eventIndex: number | null = null): void {
      const defaultEvent = {
        type: EWheelOfFortuneCampaignEventType.MONETARY,
        promoCode: null,
        amountUsd: null,
        startAt: null,
        finishAt: null
      };

      if (
        eventIndex !== null &&
        eventIndex >= 0 &&
        eventIndex < this.events.length
      ) {
        const eventToCopy = this.events[eventIndex];
        const { type, promoCode, amountUsd, startAt, finishAt } = eventToCopy;

        this.events.splice(eventIndex + 1, 0, {
          type,
          promoCode,
          amountUsd,
          startAt,
          finishAt
        });
      } else {
        this.events = [...this.events, { ...defaultEvent }];
      }
    },
    hardDateValidate(): void {
      this.$nextTick(() => {
        this.$refs.form?.validate();
      });
    }
  }
};
